import nortran from "../../img/partners/nortran.png"
import aykaar from "../../img/partners/aykaar.png"
import empos from "../../img/partners/empos.png"
import radivoje from "../../img/persons/radi.jpg"
import jamal from "../../img/persons/jamal.png"
import aleksej from "../../img/persons/aleksej.png"


export default function Contact() {
    return (
        <div className="card-look content my-6 scroll-section" id="partners">
            <h2 className="section-head">
                Our Partners
            </h2>
            <div>
                <div
                    className={'flex flex-col text-xl md:flex-row md:space-x-4 bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                    <div className={'flex flex-col md:w-1/2'}>
                        <p><span className={'font-stop'}>KOJAMI POWER</span> <span className={''}>Partner</span></p>
                        <p className={'font-bold'}>Office South East Europe</p>
                        <div className={''}>
                            <img src={nortran} alt={'nortran'} className={'h-20 cursor-pointer'} onClick={() => window.location = 'https://www.nortran.rs/'}/>
                        </div>
                        <p className={'text-base'}>NORTRAN Engineering</p>
                        <p className={'text-base'}>Ustanička 189/VP/lok 5</p>
                        <p className={'text-base'}>11050, Belgrade</p>
                        <p className={'text-base'}>Serbia</p>
                        <p className={'text-base'}>E-Mail: office@nortran.rs</p>
                    </div>
                    <div className={'flex flex-col md:w-1/2 bg-gray-300 mt-4 md:mt-0 p-4 rounded-lg'}>
                        <div className={''}>
                            <img src={radivoje} alt={'radivoje'} className={'object-cover w-24 h-24 rounded-full'}/>
                        </div>
                        <p className={'font-semibold mt-2'}>Dr. Radivoje Vračar</p>
                        <p className={'text-base'}>PhD University of Grenoble, France</p>
                        <p className={'text-base'}>M.Sc. mech.eng.</p>
                    </div>
                </div>
                <div
                    className={'flex flex-col text-xl md:flex-row md:space-x-4 bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                    <div className={'flex flex-col md:w-1/2'}>
                        <p><span className={'font-stop'}>KOJAMI POWER</span> <span className={''}>Partner</span></p>
                        <p className={'font-bold'}>Office Central Asia</p>
                        <div className={''} >
                            <img src={aykaar} alt={'aykaar'} className={'h-20 my-2 cursor-pointer'} onClick={() => window.location = 'http://aykaar.com/'}/>
                        </div>
                        <p className={'text-base'}>AYKAAR SANAT Engineering Co.</p>
                        <p className={'text-base'}>12th Danesh Street-Phase II</p>
                        <p className={'text-base'}>Toos Industrial Zone-Mashhad</p>
                        <p className={'text-base'}>Iran</p>
                        <p className={'text-base'}>E-Mail: jamalrokhsat@gmail.com</p>
                    </div>
                    <div className={'flex flex-col md:w-1/2 bg-gray-300 mt-4 md:mt-0 p-4 rounded-lg'}>
                        <div className={''}>
                            <img src={jamal} alt={'jamal'} className={'object-cover w-24 h-24 rounded-full'}/>
                        </div>
                        <p className={'font-semibold mt-2'}>Mr. Jamal Rokhsat</p>
                        <p className={'text-base'}>Mechanical Engineer</p>
                    </div>
                </div>
                <div
                    className={'flex flex-col text-xl md:flex-row md:space-x-4 bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                    <div className={'flex flex-col md:w-1/2'}>
                        <p><span className={'font-stop'}>KOJAMI POWER</span> <span className={''}>Partner</span></p>
                        <p className={'font-bold'}>Office Russia & CIS</p>
                        <div className={'cursor-pointer'} onClick={() => window.location = 'https://empos.ru/'}>
                            <img src={empos} alt={'empos'} className={'h-20 my-2'}/>
                        </div>
                        <p className={'text-base'}>ООО ТЭМК ЭМПОС</p>
                        <p className={'text-base'}>улица Малая Почтовая д.12, стр. 1 </p>
                        <p className={'text-base'}>105085, г. Москва </p>
                        <p className={'text-base'}>Россия</p>
                        <p className={'text-base'}>E-Mail: info@empos.ru</p>
                    </div>
                    <div className={'flex flex-col md:w-1/2 bg-gray-300 mt-4 md:mt-0 p-4 rounded-lg'}>
                        <div className={''}>
                            <img src={aleksej} alt={'aleksej'} className={'object-cover w-24 h-24 rounded-full'}/>
                        </div>
                        <p className={'font-semibold mt-2'}>Mr. Aleksej Mladenović</p>
                        <p className={'text-base'}>State certified Engineer</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

