import SlideShow from "../components/home/SlideShow";
import AboutUs from "../components/home/AboutUs";
import OfferTiles from "../components/home/OfferTiles";
import OfferChances from "../components/home/OfferChances";
import Products from "../components/home/Products";
import Contact from "../components/home/Contact";
import Partners from "../components/home/Partners";

function Home() {
    return (
        <div className="bg-gray-100 -mb-4 pb-6">
            <SlideShow/>
            <AboutUs/>
            <OfferTiles/>
            <OfferChances/>
            <Products/>
            <Contact/>
            <Partners/>
        </div>
    )
}

export default Home;
