import slidePic1 from "../../img/slideshow/kojami-slideshow-1.png";
import slidePic2 from "../../img/slideshow/kojami-slideshow-3.png";
import slidePic3 from "../../img/slideshow/kojami-slideshow-2.png";
import {Pulse} from 'react-motions'
import {BsChevronDoubleDown} from "react-icons/bs"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function SlideShow() {
    let settings = {
        arrows: false,
        dots: false,
        autoplay: true,
        infinite: true,
        pauseOnHover: false,
        fade: true,
        speed: 1500,
        autoplaySpeed:2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        useCSS: true
    };
    return (
        <div className="z-0 -pt-16 scroll-section" id="home">
            <div className="relative">
                <Slider {...settings} >
                    <div className="h-screen w-screen">
                        <img src={slidePic1} alt="slideshow Refinery" className="h-full min-w-full object-cover"/>
                    </div>
                    <div className="h-screen w-screen">
                        <img src={slidePic2} alt="slideshow rooftop with many industrial coolers"
                             className="h-full min-w-full object-cover "/>
                    </div>
                    <div className="h-screen w-screen">
                        <img src={slidePic3} alt="slideshow power plant with industrial coolers"
                             className="h-full min-w-full object-cover"/>
                    </div>
                </Slider>
                <div className="absolute bottom-1/2 right-0 text-white text-right pr-4 lg:pr-8">
                    <p className="font-stop text-5xl xs:text-6xl sm:text-8xl lg:text-9xl xl:text-10xl 2xl:text-11xl">KOJAMI
                        POWER</p>
                    <p className="font-light text-3xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl">International
                        Consulting &<br/>
                        Industries</p>
                </div>
                <div className="absolute flex justify-center inset-x-0 bottom-0 md:hidden">
                    <Pulse duration={2} infinite>
                        <BsChevronDoubleDown className="text-6xl text-white mb-32"/>
                    </Pulse>
                </div>
            </div>
        </div>
    )
}
