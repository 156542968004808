import React from 'react';
import {hydrate, render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import "./css/tailwind.css"
import "./css/oswald.ttf"
import "./css/sourcesans.ttf"
import "./css/standard.ttf"
import "./css/stop.ttf"
import App from './App';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<BrowserRouter>
        <App/>
    </BrowserRouter>, rootElement);
} else {
    render(<BrowserRouter>
        <App/>
    </BrowserRouter>, rootElement);
}