import React from 'react';
import clsx from 'clsx';
import {makeStyles, StylesProvider} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {VscMenu} from 'react-icons/vsc';
import logo from '../img/logo.png';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    test: {
        fontSize: "10rem",
    }
});

export default function NavBar() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="">
                <p className="text-center font-stop text-primary text-3xl mt-4">Kojami Power</p>
            </div>
            <List>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/#home'} key={'Home'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'Home'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/#about'} key={'About us'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'About us'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/#offer'} key={'Offer'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'Offer'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/#products'} key={'Products'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'Products'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/#contact'} key={'Contact'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'Contact'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/#partners'} key={'Partners'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'Partners'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
            </List>
            <StylesProvider injectFirst>
                <Divider/>
            </StylesProvider>
            <List>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/imprint'} key={'Imprint'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'Imprint'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
                <ListItem button onClick={() => window.location = 'https://kojamipower.eu/policy'}
                          key={'Privacy Policy'}>
                    <StylesProvider injectFirst>
                        <ListItemText primary={'Privacy Policy'} className="text-primary"/>
                    </StylesProvider>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className="flex fixed justify-between w-full bg-white h-16 shadow-md z-10">
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <div className="flex w-4/5 -mt-1 cursor-pointer" onClick={() => {
                        window.location = 'https://kojamipower.eu'
                    }}>
                        <div className={'flex pl-2'}>
                            <img src={logo} alt={'logo'} className={'h-12 w-12 self-center'}/>
                        </div>
                        <p className="self-center font-stop text-2xl text-primary overflow-visible">KOJAMI POWER</p>
                    </div>
                    <div className="flex md:pr-4 md:hidden">
                        <Button onClick={toggleDrawer(anchor, true)}><p className="text-4xl text-primary"><VscMenu/></p>
                        </Button>
                    </div>
                    <div className="hidden pr-4 md:flex font-heading text-xl text-primary">
                        <div
                            className="flex cursor-pointer transform duration-100 text-xl hover:text-white hover:bg-primary"
                            onClick={() => {
                                window.location.href = "https://kojamipower.eu/#home"
                            }}>
                            <p className="self-center px-4 ">
                                Home
                            </p>
                        </div>
                        <div
                            className="flex cursor-pointer transform duration-100 text-xl hover:text-white hover:bg-primary whitespace-nowrap"
                            onClick={() => {
                                window.location.href = "https://kojamipower.eu/#about"
                            }}>
                            <p className="self-center px-4">
                                About us
                            </p>
                        </div>
                        <div
                            className="flex cursor-pointer transform duration-100 text-xl hover:text-white hover:bg-primary"
                            onClick={() => {
                                window.location.href = "https://kojamipower.eu/#offer"
                            }}>
                            <p className="self-center px-4 ">
                                Offer
                            </p>
                        </div>
                        <div
                            className="flex cursor-pointer transform duration-100 text-xl hover:text-white hover:bg-primary"
                            onClick={() => {
                                window.location.href = "https://kojamipower.eu/#products"
                            }}>
                            <p className="self-center px-4 ">
                                Products
                            </p>
                        </div>
                        <div
                            className="flex cursor-pointer transform duration-100 text-xl hover:text-white hover:bg-primary"
                            onClick={() => {
                                window.location.href = "https://kojamipower.eu/#contact"
                            }}>
                            <p className="self-center px-4 ">
                                Contact
                            </p>
                        </div>
                        <div
                            className="flex cursor-pointer transform duration-100 text-xl hover:text-white hover:bg-primary"
                            onClick={() => {
                                window.location.href = "https://kojamipower.eu/#partners"
                            }}>
                            <p className="self-center px-4 ">
                                Partners
                            </p>
                        </div>
                    </div>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
