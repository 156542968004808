import React from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Routing from "./Routing";
import "./css/tailwind.css"

function App() {
    return (
        <div className="relative bg-gray-100">
            <NavBar/>
            <Routing/>
            <Footer/>
        </div>
    );
}

export default App;
