import powerplant from "../../img/iconsLogos/offer-power-plant.svg"
import datacenter from "../../img/iconsLogos/offer-data-center.svg"
import steelmill from "../../img/iconsLogos/offer-steel.svg"
import carManu from "../../img/iconsLogos/offer-car-manufacturing.svg"
import refine from "../../img/iconsLogos/offer-refinery.svg"
import dropOil from "../../img/iconsLogos/offer-oil-drop.svg"
import dropWater from "../../img/iconsLogos/offer-water-drop.svg"
import snow from "../../img/iconsLogos/offer-snow.svg"
import {useState} from "react";
import ReactCardFlip from 'react-card-flip';

export default function OfferTiles() {

    const [powerPlant, setPowerPlant] = useState(false)
    const [dataCenter, setDataCenter] = useState(false)
    const [steelMill, setSteelMill] = useState(false)
    const [car, setCar] = useState(false)
    const [refineries, setRefineries] = useState(false)
    const [applications, setApplications] = useState(false)

    return (
        <div className="font-para card-look content mt-6 scroll-section" id="offer">
            <h2 className="section-head">
                Offer
            </h2>
            <p className="text-xl md:text-2xl">
                Consulting and manufacturing for wet, dry & adiabatic <br/> cooling towers:
            </p>
            <div
                className="grid grid-cols-2 grid-rows-3 lg:w-3/4 lg:mx-auto md:grid-cols-3 md:grid-rows-2 gap-2 md:gap-3 justify-items-center mt-4 font-bold tracking-wide p-3 text-white">
                <div className="w-full">
                    <ReactCardFlip isFlipped={powerPlant} flipDirection="horizontal" infinite={true} flipSpeedBackToFront={0.8} flipSpeedFrontToBack={0.8} >
                        <div className="flex bg-primary h-36 xs:h-44 sm:h-48"
                             onClick={() => setPowerPlant(!powerPlant)}>
                            <img src={powerplant} alt="powerplant"
                                 className="w-3/4 xs:w-3/5 sm:w-7/12 lg:w-1/2 xl:w-2/5 mx-auto self-center"/>
                        </div>
                        <div className="flex bg-primary justify-center h-36 xs:h-44 sm:h-48"
                             onClick={() => setPowerPlant(!powerPlant)}>
                            <p className="pb-2 pt-1 text-sm xs:text-base sm:text-lg mx-2 self-center">Power Plants</p>
                        </div>
                    </ReactCardFlip>
                </div>
                <div className="w-full">
                    <ReactCardFlip isFlipped={dataCenter} flipDirection="horizontal" infinite={true} flipSpeedBackToFront={0.8} flipSpeedFrontToBack={0.8}>
                        <div className="flex bg-primary h-36 xs:h-44 sm:h-48"
                             onClick={() => setDataCenter(!dataCenter)}>
                            <img src={datacenter} alt="datacenter"
                                 className="w-3/4 xs:w-3/5 sm:w-7/12 lg:w-1/2 xl:w-2/5 mx-auto self-center"/>
                        </div>
                        <div className="flex bg-primary justify-center h-36 xs:h-44 sm:h-48"
                             onClick={() => setDataCenter(!dataCenter)}>
                            <p className="pb-2 pt-1 text-sm xs:text-base sm:text-lg mx-2 self-center">Data Centers</p>
                        </div>
                    </ReactCardFlip>
                </div>
                <div className="w-full">
                    <ReactCardFlip isFlipped={steelMill} flipDirection="horizontal" infinite={true} flipSpeedBackToFront={0.8} flipSpeedFrontToBack={0.8}>
                        <div className="flex bg-primary h-36 xs:h-44 sm:h-48" onClick={() => setSteelMill(!steelMill)}>
                            <img src={steelmill} alt="steelmill"
                                 className="w-3/4 xs:w-3/5 sm:w-7/12 lg:w-1/2 xl:w-2/5 mx-auto self-center"/>
                        </div>
                        <div className="flex bg-primary justify-center h-36 xs:h-44 sm:h-48"
                             onClick={() => setSteelMill(!steelMill)}>
                            <p className="pb-2 pt-1 text-sm xs:text-base sm:text-lg mx-2 self-center">Steel Mills</p>
                        </div>
                    </ReactCardFlip>
                </div>
                <div className="w-full">
                    <ReactCardFlip isFlipped={car} flipDirection="horizontal" infinite={true} flipSpeedBackToFront={0.8} flipSpeedFrontToBack={0.8}>
                        <div className="flex bg-primary h-36 xs:h-44 sm:h-48" onClick={() => setCar(!car)}>
                            <img src={carManu} alt="car manufactory"
                                 className="w-3/4 xs:w-3/5 sm:w-7/12 lg:w-1/2 xl:w-2/5 mx-auto self-center"/>
                        </div>
                        <div className="flex bg-primary justify-center h-36 xs:h-44 sm:h-48"
                             onClick={() => setCar(!car)}>
                            <p className="pb-2 pt-1 text-sm xs:text-base sm:text-lg mx-2 self-center">Car manufacturing
                                companies</p>
                        </div>
                    </ReactCardFlip>
                </div>
                <div className="w-full">
                    <ReactCardFlip isFlipped={refineries} flipDirection="horizontal" infinite={true} flipSpeedBackToFront={0.8} flipSpeedFrontToBack={0.8}>
                        <div className="flex bg-primary h-36 xs:h-44 sm:h-48"
                             onClick={() => setRefineries(!refineries)}>
                            <img src={refine} alt="refinery"
                                 className="w-3/4 xs:w-3/5 sm:w-7/12 lg:w-1/2 xl:w-2/5 mx-auto self-center"/>
                        </div>
                        <div className="flex bg-primary justify-center h-36 xs:h-44 sm:h-48"
                             onClick={() => setRefineries(!refineries)}>
                            <p className="pb-2 pt-1 text-sm xs:text-base sm:text-lg mx-2 self-center">Refineries</p>
                        </div>
                    </ReactCardFlip>
                </div>
                <div className="w-full">
                    <ReactCardFlip isFlipped={applications} flipDirection="horizontal" infinite={true} flipSpeedBackToFront={0.8} flipSpeedFrontToBack={0.8}>
                        <div className="flex bg-primary h-36 xs:h-44 sm:h-48"
                             onClick={() => setApplications(!applications)}>
                            <div className="flex self-center">
                                <img src={dropWater} alt="drop of water" className="w-1/3"/>
                                <img src={snow} alt="snowflake" className="w-1/3"/>
                                <img src={dropOil} alt="drop of oil" className="w-1/3"/>
                            </div>
                        </div>
                        <div className="flex bg-primary justify-center h-36 xs:h-44 sm:h-48"
                             onClick={() => setApplications(!applications)}>
                            <p className="pb-2 pt-1 text-sm xs:text-base sm:text-lg mx-2 self-center">applications where
                                water / oil is cooled with ambient air</p>
                        </div>
                    </ReactCardFlip>
                </div>
            </div>
        </div>
    )
}

