import team from "../../img/iconsLogos/about-us-team.png"
import software from "../../img/iconsLogos/about-us-software.png"

export default function AboutUs() {
    return (
        <div className="font-standard text-xl md:text-2xl scroll-section" id="about">
            <div
                className="card-look content mt-3">
                <h2 className="section-head">About us</h2>
                <p><span className="font-stop text-2xl">KOJAMI POWER</span> is involved in providing
                    consulting
                    to companies in search for solutions for
                    wet, dry & adiabatic cooling towers. </p>
            </div>
            <div className="content sm:flex-row ">
                <div className="card-look flex flex-col sm:w-1/2 justify-center pt-6 mt-6 sm:mr-3">
                    <div className="w-1/2 mx-auto">
                        <img src={software} alt="team" className="my-2 mx-auto p-2"/>
                    </div>
                    <div className="mt-2">
                        <p>We manufacture at the
                            highest quality level on the
                            basis of data supplied by
                            most modern thermodynamic
                            selection software. </p>
                    </div>
                </div>
                <div className="card-look flex flex-col sm:w-1/2 justify-center pt-6 mt-6 sm:ml-3">
                    <div className="w-1/2 mx-auto">
                        <img src={team} alt="team" className="mt-4 mx-auto p-2"/>
                    </div>
                    <div className="mt-2">
                        <p>The company is backed by competent international teams consisting of professionals with
                            decades of experience in the field of wet, dry & adiabatic cooling systems based in Germany,
                            India, Serbia and Iran.</p>
                    </div>
                </div>
            </div>
        </div>

    );
}
