function Error404(){
    const pathname = window.location.pathname
    return(
        <div className="flex justify-center bg-green-400 h-screen">
            <div className="self-center text-primary space-y-2 font-para text-3xl xs:text-4xl md:text-5xl">
                <p className="font-bold pb-2">404</p>
                <p>Something went Wrong</p>
                <p>Can't find a page at <span className="italic">"{pathname}"</span></p>
            </div>
        </div>
    )
}

export default Error404;