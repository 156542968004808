import micro1 from "../../img/products/products_micro1.png"
import micro2 from "../../img/products/products_micro2.png"
import medium1 from "../../img/products/products_medium1.png"
import medium2 from "../../img/products/products_medium2.png"
import large1 from "../../img/products/products_large1.png"
import large2 from "../../img/products/products_large2.png"
import beforeAdia from "../../img/products/before_adia.png"
import afterAdia from "../../img/products/after_adia.png"
import wetSmall from "../../img/products/products-wet-small.jpeg"
import wetMid from "../../img/products/products-wet-mid.jpeg"
import wetBigSide from "../../img/products/products-wet-big-side.jpeg"
import wetBig1 from "../../img/products/products-wet-big1.jpeg"
import wetBig2 from "../../img/products/products-wet-big2.jpeg"

export default function Products() {
    return (
        <div className="card-look content mt-6 scroll-section" id="products">
            <h2 className="section-head">
                Products
            </h2>
            <p className="text-xl md:text-2xl">
                We provide the world’s largest range of adiabatic coolers with a high performance in the field of water
                saving.
            </p>
            <div className={'bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                <p className={'text-xl font-extrabold tracking-wide'}>Adiabatic & dry coolers</p>
                <p className={'text-xl'}>(closed circuit)</p>
                <p className={'text-lg mt-1'}>Our adiabatic coolers come in 3 capacities:</p>
                <div className={'flex flex-col md:space-x-4 md:flex-row'}>
                    <div className={'md:w-1/2'}>
                        <p className={'font-semibold tracking-wide mt-2 md:mt-4 text-lg'}>MICRO coolers:</p>
                        <p className={'text-lg'}>12 kW – 140 kW</p>
                        <div className={'flex justify-center py-4 bg-white rounded-lg mt-2'}>
                            <div className={'w-2/5'}>
                                <img src={micro1} alt={'micro1'}/>
                            </div>
                            <div className={'w-2/5'}>
                                <img src={micro2} alt={'micro2'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'md:w-1/2'}>
                        <p className={'font-semibold tracking-wide mt-4 text-lg'}>MEDIUM coolers:</p>
                        <p className={'text-lg'}>141 kW – 2200 kW</p>
                        <div className={'flex justify-center py-4 bg-white rounded-lg mt-2'}>
                            <div className={'w-5/12'}>
                                <img src={medium1} alt={'medium1'} className={'py-2'}/>
                            </div>
                            <div className={'w-5/12'}>
                                <img src={medium2} alt={'medium2'} className={'py-2'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <p className={'font-semibold tracking-wide mt-4 text-lg'}>LARGE coolers:</p>
                <p className={'text-lg'}>2,2 MW – 1100 MW</p>
                <div className={'flex justify-center py-4 bg-white rounded-lg mt-2'}>
                    <div className={'w-5/12'}>
                        <img src={large1} alt={'large1'}/>
                    </div>
                    <div className={'w-5/12'}>
                        <img src={large2} alt={'large2'}/>
                    </div>
                </div>
            </div>
            <div className={'bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                <p className={'text-xl font-extrabold tracking-wide'}>Wet coolers</p>
                <p className={'text-xl'}>(open & closed circuit)</p>
                <p className={'text-lg mt-1'}>We offer customized designs in terms of sound, dimension and materials.
                    The capacity ranges from 50 kW to 20 MW. For more information, please send us an inquiry. </p>
                <div className={'flex flex-col md:space-x-4 md:flex-row'}>
                    <div className={'flex justify-center py-4 bg-white rounded-lg mt-2 md:w-1/2'}>
                        <div className={'w-5/12 self-center'}>
                            <img src={wetSmall} alt={'wet small'} className={'h-36'}/>
                        </div>
                        <div className={'w-5/12'}>
                            <img src={wetMid} alt={'wet mid'} className={'h-36'}/>
                        </div>
                    </div>
                    <div className={'flex justify-around py-4 bg-white rounded-lg mt-2 md:w-1/2'}>
                        <div className={'w-5/12'}>
                            <img src={wetBigSide} alt={'wet big side'} className={'h-36'}/>
                        </div>
                        <div className={'w-5/12'}>
                            <img src={wetBig1} alt={'wet big 1'} className={'h-36'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                <p className={'text-xl font-extrabold tracking-wide'}>Adiabatization</p>
                <p className={'text-lg mt-1'}>We offer the adiabatization of existing dry-coolers, dry-cooled condensers
                    and
                    air-inlet sections
                    of gas-turbines with high performance adiabatic walls.</p>
                <div className={'flex flex-col md:flex-row md:justify-around'}>
                    <div className={'pt-4 pb-2 px-2 bg-white rounded-lg mt-3 md:w-2/5'}>
                        <img src={beforeAdia} alt={'before Adia'} className={'mx-auto h-36'}/>
                        <p className={'text-lg text-center'}>before</p>
                    </div>
                    <div className={'pt-4 pb-2 px-2 bg-white rounded-lg mt-3 md:w-2/5'}>
                        <img src={afterAdia} alt={'after Adia'} className={'mx-auto h-36'}/>
                        <p className={'text-lg text-center'}>after</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
