import earth from "../../img/slideshow/offer2-earth.svg"
import rain from "../../img/slideshow/offer2-rainfall.svg"
import adia from "../../img/slideshow/offer2-adia.png"
import Slider from "react-slick";

export default function OfferChances() {
    let settings = {
        arrows: false,
        dots: true,
        autoplay: true,
        infinite: true,
        fade: false,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        speed: 800,
        swipe: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className="font-para card-look content mt-6">
            <Slider {...settings}>
                <div className="flex">
                    <div className="flex flex-col sm:flex-row self-center">
                        <div className="w-1/2 sm:w-1/4 mx-auto mb-4 ">
                            <img src={earth} alt="earth"/>
                        </div>
                        <div className="flex sm:w-3/4">
                            <p className="text-xl md:text-2xl self-center">
                                We determine how you can save water and electricity consumption or increase the
                                performance of your plant by optimizing the cooling system.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex flex-col sm:flex-row self-center">
                        <div className="w-1/2 sm:w-1/4 mx-auto my-5 ">
                            <img src={adia} alt="earth"/>
                        </div>
                        <div className="flex sm:w-3/4">
                            <p className="text-xl md:text-2xl px-2 self-center">
                                When it comes to increasing the performance of power plants or old dry cooling systems,
                                we can develop concepts for the adiabatization of existing plants.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex flex-col sm:flex-row self-center">
                        <div className="w-1/2 sm:w-1/4 mx-auto mb-4 ">
                            <img src={rain} alt="water solutions with rain"/>
                        </div>
                        <div className="flex sm:w-3/4">
                            <p className="text-xl md:text-2xl self-center">
                                In case of water shortage on site we will work out possible solutions for you.
                            </p>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
        /*
                <div className="font-standard card-look mx-auto content mt-6">
                    <div className="w-1/4">
                        <img src={earth} alt="earth" className="w-2/3 mx-auto mt-2 mb-4 self-center"/>
                    </div>
                    <p className="text-xl md:text-2xl self-center">
                        We determine how you can save water and electricity consumption or increase the performance of
                        your
                        plant by optimizing the cooling system.
                    </p>
                </div>
                <div className="font-standard card-look content mt-6">
                    <div className="w-1/4">
                        <img src={earth} alt="earth" className="w-2/3 mx-auto mt-2 mb-4 self-center"/>
                    </div>
                    <p className="text-xl md:text-2xl self-center">
                        When it comes to increasing the performance of power plants or old dry cooling systems, we can
                        develop concepts for
                        the adiabatization of
                        existing plants.
                    </p>
                </div>
                <div className="font-standard card-look content mt-6">
                    <div className="w-1/4">
                        <img src={rain} alt="earth" className="w-2/3 mx-auto mt-2 mb-4 self-center"/>
                    </div>
                    <p className="text-xl md:text-2xl self-center">
                        In case of water
                        shortage on site we
                        will work out possible
                        solutions for you.
                    </p>
                </div>
            </div>
        */
    )
}
