import jag from "../../img/persons/jag.png"
import michael from "../../img/persons/michael.png"
import beni from "../../img/persons/beni.png"


export default function Contact() {
    return (
        <div className="card-look content my-6 scroll-section" id="contact">
            <h2 className="section-head">
                Contact
            </h2>
            <div className="text-xl md:text-2xl">
                <p>If we have sparked your interest please don’t hesitate to contact us.</p>
                <p className="mt-2">We’ll gladly answer all of your questions and we are always available for a personal
                    presentation.</p>
            </div>
            <div>
                <div className={'flex flex-col text-xl md:flex-row md:space-x-4 bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                    <div className={'flex flex-col md:w-1/2'}>
                        <p><span className={'font-stop'}>KOJAMI POWER</span> <span className={''}>International</span></p>
                        <p className={''}>Consulting & Industries GmbH*</p>
                        <p className={'font-bold'}>Office Europe & Middle East</p>
                        <p className={'text-base'}>Major-Scherer-Straße 18</p>
                        <p className={'text-base'}>78224, Singen</p>
                        <p className={'text-base'}>Germany</p>
                        <p className={'text-base'}>E-Mail: m.kost@kojami.eu</p>
                    </div>
                    <div className={'flex flex-col md:w-1/2 bg-primary text-white mt-4 md:mt-0 p-4 rounded-lg'}>
                        <div className={''}>
                            <img src={michael} alt={'michael'} className={'object-cover w-24 h-24 rounded-full'}/>
                        </div>
                        <p className={'font-semibold mt-2'}>Mr. Michael Kost</p>
                        <p className={'text-base'}>CEO</p>
                        <p className={'text-base'}>Master Professional of Technical Management (CCI)</p>
                    </div>
                </div>
                <div className={'flex flex-col text-xl md:flex-row md:space-x-4 bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                    <div className={'flex flex-col md:w-1/2'}>
                        <p><span className={'font-stop'}>KOJAMI POWER</span> <span className={''}>International</span></p>
                        <p className={''}>Consulting & Industries GmbH*</p>
                        <p className={'font-bold'}>Office India & South East Asia</p>
                        <p className={'text-base'}>Plusoffice, Landmark Cyber Park Building</p>
                        <p className={'text-base'}>Sector 67, Gurgaon, Haryana</p>
                        <p className={'text-base'}>India</p>
                        <p className={'text-base'}>E-Mail: jss@kojamipower.eu</p>
                    </div>
                    <div className={'flex flex-col md:w-1/2 bg-primary text-white mt-4 md:mt-0 p-4 rounded-lg'}>
                        <div className={''}>
                            <img src={jag} alt={'jag'} className={'object-cover w-24 h-24 rounded-full'}/>
                        </div>
                        <p className={'font-semibold mt-2'}>Dr. Jagjit Singh Sehra </p>
                        <p className={'text-base'}>CEO</p>
                    </div>
                </div>
                <div className={'flex flex-col text-xl md:flex-row md:space-x-4 bg-gray-200 text-left mt-4 p-3 md:p-6 rounded-lg'}>
                    <div className={'flex flex-col md:w-1/2'}>
                        <p><span className={'font-stop'}>KOJAMI</span></p>
                        <p className={''}>International Consulting d.o.o.</p>
                        <p className={'font-bold'}>China | Japan | Singapore</p>
                        <p className={'text-base'}>Džordža Vašingtona 48</p>
                        <p className={'text-base'}>11000, Belgrade</p>
                        <p className={'text-base'}>Serbia</p>
                        <p className={'text-base'}>E-Mail: b.kost@kojami.eu</p>
                    </div>
                    <div className={'flex flex-col md:w-1/2 bg-primary text-white mt-4 md:mt-0 p-4 rounded-lg'}>
                        <div className={''}>
                            <img src={beni} alt={'beni'} className={'object-cover w-24 h-24 rounded-full'}/>
                        </div>
                        <p className={'font-semibold mt-2'}>Mr. Benjamin Kost</p>
                        <p className={'text-base'}>currently studying for Business languages (Chinese)</p>
                        <p className={'text-base'}>Asia and Management, Bachelor of Arts (B. A.)</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

