import React from "react";
import {Switch, Route} from "react-router-dom";
import Policy from "./pages/Policy.js";
import Imprint from "./pages/Imprint.js";
import Home from "./pages/Home.js";
import Error404 from "./pages/Error404.js";

const Routing = () => {
    return (
        <Switch>
            <Route exact path="/imprint" component={Imprint}/>
            <Route exact path="/policy" component={Policy}/>
            <Route exact path="/" component={Home}/>
            <Route component={Error404}/>
        </Switch>
    );
}

export default Routing;
