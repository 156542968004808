import {VscMail} from 'react-icons/vsc';
import {Breadcrumbs, Link} from "@material-ui/core";
import {StylesProvider} from "@material-ui/core/styles";
import React from "react";
import dazesace from "../img/iconsLogos/daze-sace-logo.svg"

export default function Footer() {
    return (
        <div className="bg-primary text-white">
            <div className="flex flex-col max-w-screen-md justify-center mx-auto pt-4 pb-6">
                <div className="px-6">
                    <p className="font-stop text-lg">
                        KOJAMI POWER
                    </p>
                    <p className="text-md">
                        International Consulting & Industries GmbH*<br/>
                        Major-Scherer-Straße 18<br/>
                        78224 Singen, Germany <br/>
                        <VscMail className="inline-block mr-2 -mt-0.5"/>info@kojami.eu
                    </p>
                    <p className={'text-xs mt-3 text-center'}>* <span className={'font-stop text-sm'}>KOJAMI POWER</span> International Consulting & Industries GmbH is currently under registration process.</p>
                </div>
                <div className="flex justify-center mt-4">
                    <StylesProvider injectFirst>
                        <Breadcrumbs className="px-6">
                            <Link href="https://kojamipower.eu/#about">
                                About us
                            </Link>
                            <Link href="https://kojamipower.eu/#offer">
                                Offer
                            </Link>
                            <Link href="https://kojamipower.eu/#products">
                                Products
                            </Link>
                            <Link href="https://kojamipower.eu/#contact">
                                Contact
                            </Link>
                            <Link href="https://kojamipower.eu/#partners">
                                Partners
                            </Link>
                        </Breadcrumbs>
                    </StylesProvider>
                </div>
                <div className="flex justify-center">
                    <StylesProvider injectFirst>
                        <Breadcrumbs className="px-6">
                            <Link href="/imprint">
                                Imprint
                            </Link>
                            <Link href="/policy">
                                Privacy Policy
                            </Link>
                        </Breadcrumbs>
                    </StylesProvider>
                </div>
                <div className="flex flex-col justify-center mt-4 w-1/5 md:w-2/12 mx-auto">

                    <p className="text-xs font-extralight text-center">made by</p>
                    <div className="transform-gpu hover:scale-110 duration-150">
                        <img src={dazesace} alt="dazesace dev logo" className="w-4/5 md:w-3/5 max-w-sm mx-auto mt-1"
                             onClick={redirect}/>
                    </div>

                </div>
                <p className="text-xs text-center mt-4 font-light">© 2021 KOJAMI POWER, all rights reserved</p>
            </div>
        </div>
    )
}

function redirect() {
    window.location.href = "http://dazesace.com"
}
